#kt_aside_nav_tabs li.active a {
	background-color: #222;
	animation-name: color !important;
	animation-duration: 2s;
	animation-iteration-count: infinite;

	.ki-abstract-26 .path1:before {
		opacity: 0.7;
		color: #fff;
	}

	.ki-abstract-26 .path2:before {
		color: #fff;
		opacity: 0.9;
	}

	&:hover {
		background: #038b8b !important;
	}
}

.bgi-size-cover {
	background-position: center !important;
}

@keyframes color {
	0% {
		background-color: #ff1111;
	}

	50% {
		background-color: #bf0c0c;
	}

	100% {
		background-color: #ff1111;
	}
}

.custom-list.active {
	background: #eee;
}

.mb-on-note-child {
	padding-bottom: 40px;

	p,
	span {
		background: transparent !important;
		background-color: transparent !important;
	}
}

textarea {
	width: 100%;
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--bs-gray-700);
	transition: color 0.2s ease;
	min-height: 160px;
}

.deactivated-user {
	opacity: 0.5;
}

@media (min-width: 960px) {
	.card .carousel .control-dots {
		bottom: 26px;
		margin: 0;
	}
}

.flex {
	display: flex;
}

.justify-content-space-between {
	justify-content: space-between;
}

.hide-input input {
	width: 0 !important;
	height: 0 !important;
	overflow: hidden;
	opacity: 0;
	position: absolute;
}

.rdw-editor-main {
	padding: 0 20px;
	font-size: 1.15rem;
	line-height: 1.5;
}

.rdw-editor-wrapper {
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--bs-gray-700);
	transition: color 0.2s ease;
	min-height: 300px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.replies-box {
	padding: 0px 30px;
	border-bottom: 1px #eee solid;
	padding-bottom: 20px;

	.image-box {
		display: flex;
		align-items: baseline;

		time {
			font-size: 12px;
			margin-left: 10px;
		}
	}

	.reply-item {
		margin-bottom: 15px;
	}
}

.reply-box {
	padding: 0 20px;

	textarea {
		padding: 10px;

		&:focus {
			box-shadow: none !important;
		}
	}
}
