//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import "./core/base/functions";
@import "./core/base/mixins";
@import "./core/components/mixins";
@import "./core/vendors/plugins/mixins";

// Custom variables
@import "components/variables.custom";
@import "./core/components/variables";
@import "./core/components/variables-dark";

// Bootstrap initializaton
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 3rd-Party plugins variables
@import "./core/vendors/plugins/variables";
@import "./core/vendors/plugins/variables-dark";

// Custom layout variables
@import "layout/variables";
@import "hack";

.block {
	display: block;
}

.add-button-box {
	position: absolute;
	bottom: 4px;
	right: -60px;
	display: block;
	width: auto;
}

.relative {
	position: relative;
}

.fw {
	width: 100%;
}

.form-control:disabled {
	background-color: transparent !important;
	border-color: transparent !important;
	padding-left: 0 !important;
}
